import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { Link } from 'gatsby'

const meta = {
  title: 'Page not found',
}

const NotFoundPg = () => (
  <DefaultLayout {...meta} className="ctnr padding">
    <h1 className="h1">{meta.title}...</h1>

    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
    </ul>
  </DefaultLayout>
)

export default NotFoundPg
